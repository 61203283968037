import { useEffect, useState, lazy, Suspense } from 'react';

//import 'antd/dist/antd.min.css'
import '../styles/PurifiedGlobalAntd.css'
import '../styles/Global.css'
import '../styles/App.css'

import '../styles/EmptyHeaderHero.css'
import '../styles/Footer.css'
import '../styles/GlobalFooter.css'
import '../styles/GlobalHeader.css'
import '../styles/Header.css'
import '../styles/HeaderHero.css'
import '../styles/index.css'
import '../styles/404.css'

import '../styles/Login.css'
import '../styles/EmailUpdate.css'
import '../styles/QSLogin.css'

import { UserContextProvider } from '../src/context'
import { useApiData, ApiDataProvider  } from '../src/context/apiContext';
// import Metadata from '../src/components/Metadata';
import GenesysTextWithUs from '../src/components/GenesysTextWithUs';
import { getTestingEnvToken, getHostName } from '../src/utils/domainUtil';
import { getWebsiteMaintenanceMessage, getWebsiteMaintenanceStatus } from '../src/services';
import { getHolidayPopupMessage, getHolidayPopupStatus, getWebMessageStatus } from '../src/services/Api.js';
import RoundyChatPlaceholder from '../src/components/RoundyChatPlaceholder/RoundyChatPlaceholder';
import Cookies from 'js-cookie';

const StickyRoundyChatNow = lazy(() => import("../src/components/StickyRoundyChatNow.js"));

export default function App({ Component, pageProps }) {
  return (
    <ApiDataProvider>
      <AppContent Component={Component} pageProps={pageProps} />
    </ApiDataProvider>
  );
}

function AppContent({ Component, pageProps }) {
  const { websiteStatus, websiteMessage, updateApiData1, updateApiData2, holidayPopupStatus, holidayPopupMessage, webMessageStatus, apiData5 } = useApiData();
  const [hasLoadedWebMessage, setHasLoadedWebMessage] = useState(false)
  const [showWebTool, setShowWebTool] = useState(true)
  const [showGenesysTextWithUs, setShowGenesysTextWithUs] = useState(false)
  const handleShowWebMessage = event => {
    if (!hasLoadedWebMessage) {
      setHasLoadedWebMessage(true);
    }
  };

  if (typeof window === 'object') {
    window.handleShowWebMessage = () => setHasLoadedWebMessage(true)
    window.__WAS_SSR = true
  }

  useEffect(() => {
    if(process.env.NODE_ENV != 'development'){
      if (Cookies.get('CTLE', { domain: '.cure.com' }) != getTestingEnvToken() && getHostName() != 'prod') {
        window.location = "https://www.cure.com";
      }
    }

    //loading Pendo tracking script
    console.log("loading pendo...")
    const apiKey = '9514fe0f-b639-4d36-6b1c-bd052593406b';
    var o, v, w, x, y, z; o = window['pendo'] = window['pendo'] || {}; o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w)(function (m) {
      o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
    })(v[w]);
    y = document.createElement('script'); y.async = !0; y.src = 'https://content.pendo.cure.com/agent/static/' + apiKey + '/pendo.js';
    z = document.getElementsByTagName('script')[0]; z.parentNode.insertBefore(y, z);

    pendo.initialize({
      visitor: {
        id: ""
      },
      account: {
        id: ""
      }
    });

    // Retrieves maintenance status and message from server
    const fetchData = async () => {
      try {
        const websiteStatusResponse = await getWebsiteMaintenanceStatus();
        const websiteMessageResponse = await getWebsiteMaintenanceMessage();
        const holidayPopupStatusResponse = await getHolidayPopupStatus();
        const holidayPopupMessageResponse = await getHolidayPopupMessage();
        const webMessageResponse = await getWebMessageStatus();

        websiteStatus(websiteStatusResponse);
        websiteMessage(websiteMessageResponse);
        holidayPopupStatus(holidayPopupStatusResponse);
        holidayPopupMessage(holidayPopupMessageResponse);
        webMessageStatus(webMessageResponse);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [])
  
  return (
    <div className="cure-main-container">
      {/* <Nav /> */}
      {/* <Metadata /> */}
      <div className="wrapper" style={{ position: "relative" }}>
        <UserContextProvider>
          <Component {...pageProps} />
        </UserContextProvider>

        {(!hasLoadedWebMessage) && (
          <RoundyChatPlaceholder
            setShowGenesysTextWithUs={setShowGenesysTextWithUs}
            handleShowMessagingTool={handleShowWebMessage}
            show={showWebTool}
            setShow={setShowWebTool}
            webMessageStatus={apiData5?.data?.Result}
          />
        )}
        <GenesysTextWithUs show={showGenesysTextWithUs} setShow={setShowGenesysTextWithUs} setShowWebTool={setShowWebTool} handleShowMessagingTool={handleShowWebMessage} />
        <Suspense fallback={<div></div>}>
          {
            hasLoadedWebMessage ?
              <StickyRoundyChatNow setShowGenesysTextWithUs={setShowGenesysTextWithUs} /> :
              null
          }
        </Suspense>
      </div>
    </div>
  )
}
