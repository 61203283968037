
const RoundyChatPlaceholder = ({ setShowGenesysTextWithUs, show, setShow, handleShowMessagingTool, webMessageStatus }) => {
  if (typeof window === 'object') {
    window.setShowGenesysTextWithUs = () => { setShowGenesysTextWithUs(true); setShow(false) }
  }

  const roundyClick = () => {
    if (webMessageStatus === "Active") {
      setShowGenesysTextWithUs(true);
    }
    else {
      setShowGenesysTextWithUs(false);
      handleShowMessagingTool();
    }
    setShow(false);
  }
  return (
    <>
      <div className='roundy-chatnow'
        style={{ display: (show ? "block" : "none") }}
        onClick={roundyClick}
      >
        <div className="roundy-chatnow-close" style={{
          position: "absolute",
          display: "block",
          top: "-5px",
          right: "14px",
          width: "16px",
          lineHeight: "16px",
          textAlign: "center",
          cursor: "pointer",
          textDecoration: "none",
          color: "rgb(0, 0, 0)",
          fontFamily: "Arial",
          borderRadius: "50%",
          backgroundColor: "rgba(100, 100, 100, 0.4)",
          cursor: "pointer",
          zIndex: 5000000000
        }}
          onClick={(e) => { e.stopPropagation(); setShow(false) }}>
          ×
        </div>
      </div>
    </>
  );
};

export default RoundyChatPlaceholder;